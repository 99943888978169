import Layout from "../components/layout"
import CustomHelmet from "../components/CustomHelmet"
import React, { useState } from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import Tab from "@material-ui/core/Tab/Tab"
import Tabs from "@material-ui/core/Tabs/Tabs"
import css from "classnames"
import Img from "gatsby-image"
import { VideoContainer } from "../components/Videos/VideoContainer"
import { CallToAction } from "../components/CallToAction"

const styles = require("./auto-estudio.module.scss")

const foto = require("../images/estudiar-ingles-al-mar.png")

const IntroText = (props) => {
  return (
    <div className={css(styles.text, props.className)}>
      <h1>
        {props.header}
      </h1>
      <div>
        <h2>
          {props.subHeader}
        </h2>
        <div className={styles.icons}>
          <img className={styles.img} src={require("../images/speaking.svg")} alt={"speaking"}/>
          <img className={styles.img} src={require("../images/reading.svg")} alt={"reading"}/>
          <img className={styles.img} src={require("../images/writing.svg")} alt={"writing"}/>
          <img className={styles.img} src={require("../images/listening.svg")} alt={"listening"}/>
        </div>
        {props.youtubeId ? <VideoContainer id={props.youtubeId} description={""} title={""}/> : <></>}
        {props.children}
      </div>
    </div>
  )
}

const Footer = () => (
  <div className={css(styles.autoEstudio, styles.last)}>
    <section>
      Por último, Déjanos saber en nuestras redes sociales qué te inspira a estudiar inglés y cómo
      vas con tu
      auto - estudio, con el Hashtag <span className={styles.secondaryColor}>#</span>DesdeMaltaInspira
      que en
      nuestras publicaciones de esta semana estaremos inspirándote a no abandonar tu sueño!
    </section>
    <div className={styles.footer}><h3><span className={styles.secondaryColor}>#</span>DesdeMaltaInspira
    </h3>
    </div>
  </div>
)


const AutoEstudio = () => {

  const [value, setValue] = useState(0)
  const [level, setLevel] = useState(0)

  const toWeekOne = () => {
    window.scrollTo(0, 0)
    setValue(1)
  }

  function handleChange(event, newValue) {
    window.scrollTo(0, 0)
    setValue(newValue)
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          homeBbc: file(relativePath: { eq: "home-bbc.png"}) {
            childImageSharp {
              sizes(maxWidth: 1000) {
                ...GatsbyImageSharpSizes
              }
            }
          },
          bbcInter: file(relativePath: { eq: "bbc-intermediate.png"}) {
            childImageSharp {
              sizes(maxWidth: 1000) {
                ...GatsbyImageSharpSizes
              }
            }
          },
          lowerIntermediate: file(relativePath: { eq: "lowerInter.png"}) {
            childImageSharp {
              sizes(maxWidth: 1000) {
                ...GatsbyImageSharpSizes
              }
            }
          },
          intermediate: file(relativePath: { eq: "curso.png"}) {
            childImageSharp {
              sizes(maxHeight: 800) {
                ...GatsbyImageSharpSizes
              }
            }
          },
          basico: file(relativePath: { eq: "basico.png"}) {
            childImageSharp {
              sizes(maxHeight: 800) {
                ...GatsbyImageSharpSizes
              }
            }
          },
          semanaDos: file(relativePath: { eq: "semanaDos.png"}) {
            childImageSharp {
              sizes(maxWidth: 1000) {
                ...GatsbyImageSharpSizes
              }
            }
          },
          elllo: file(relativePath: { eq: "elllo.png"}) {
            childImageSharp {
              sizes(maxWidth: 1000) {
                ...GatsbyImageSharpSizes
              }
            }
          },
          tflat: file(relativePath: { eq: "Tflat.png"}) {
            childImageSharp {
              sizes(maxWidth: 1000) {
                ...GatsbyImageSharpSizes
              }
            }
          },
          elsa: file(relativePath: { eq: "ElsaApp.png"}) {
            childImageSharp {
              sizes(maxWidth: 1000) {
                ...GatsbyImageSharpSizes
              }
            }
          },
          }
    `}
      render={data => {
        return (
          <Layout>
            <CustomHelmet
              title={"Desde Malta | Guia de auto-estudio de inglés"}
              description={"La guia de auto-estudio de inglés Desde Malta, es una herramienta gratuita que te permitirá mediante varios recursos, mejorar tu inglés"}
              link={"/auto-estudio/"}
              image={foto}
            />
            <Tabs className={styles.tabs} value={value} onChange={handleChange} aria-label="simple tabs example"
                  indicatorColor={"primary"}>
              <Tab key={"Intro"} label={"Intro"}/>
              <Tab key={"Week 1"} label={"Week 1"}/>
              <Tab key={"Week 2"} label={"Week 2"}/>
              <Tab key={"Week 3"} label={"Week 3"}/>
              <Tab key={"Week 4"} label={"Week 4"}/>
            </Tabs>
            {value === 0 ? <div className={styles.autoEstudio}>
              <div className={styles.page}>
                <div className={styles.image}><img className={styles.img} src={require("../images/lightbulb.svg")}
                                                   alt={"lightbulb"}/></div>
                <IntroText header={"GUÍA DE AUTO-ESTUDIO"} subHeader={"DE INGLÉS / DESDEMALTA"}>
                  <section className={css(styles.border, styles.intro)}>
                    <span className={styles.start}>U</span>n momento como el que estamos viviendo, a pesar de ser
                    difícil, caótico y extraño; es una oportunidad única para
                    probar de qué están hechos nuestros sueños; y por ello, hoy te queremos compartir esta iniciativa en
                    la que hemos venido trabajando en las últimas semanas, para poner nuestro granito de arena y no
                    dejar que un momento difícil mate tus sueños; sino que tengas herramientas diferentes, para trabajar
                    por ellos mientras todo vuelve a la normalidad.
                  </section>
                </IntroText>
              </div>
              <div className={styles.page}>

                <div className={styles.text}>
                  <section className={styles.square}>
                    <p><Link to={"/paso-a-paso/"}>Asesorar tu Viaje a Malta</Link> con el objetivo de estudiar inglés,
                      ha sido la intención que ha dado
                      vida a Desde Malta, y creemos que el verbo "viajar" puede dar una espera; pero que el objetivo de
                      mejorar tu inglés, puede empezar a
                      alcanzarse por medio de otras acciones...</p>
                    <p>Es por ello, que en las siguientes 4 semanas vamos a compartir contigo, una propuesta de
                      estudios, estructurada y enfocada en cada una de las habilidades necesarias para el dominio de la
                      lengua. Nos hemos puesto en la tarea de organizar algunos recursos que encuentras en internet,
                      para que no te pierdas en ellos; pero sobretodo para que tengas un objetivo que con disciplina
                      puedas alcanzar!</p>
                  </section>
                </div>
                <div className={styles.image}><img className={styles.img} src={require("../images/book.svg")}
                                                   alt={"book"}/></div>
              </div>
              <div className={styles.page}>
                <div className={styles.image}><img className={styles.img} src={require("../images/plane.svg")}
                                                   alt={"plane"}/></div>
                <div className={styles.text}>
                  <section className={styles.border}>
                    Lo mínimo que puedes alcanzar siguiendo las instrucciones cada semana, es una mejora en tu nivel
                    inglés; pero si realmente te apasionas estudiando, muy seguramente no querrás parar después de
                    terminar las 4 semanas. Y para ello, estaremos aquí en caso de que decidas que ya es momento de
                    planear tu viaje de estudios, para asesorarte en todo.
                  </section>
                  <section className={styles.border}>
                    Esta primera semana vamos a hacer una sesión de "calentamiento", para refrescar un poco el
                    conocimiento que tienes de la lengua, te daremos algunos tips, acerca de cómo estudiar de manera
                    efectiva; y lo mejor, es que vamos a dejarte material para los diferentes niveles de inglés...
                  </section>
                </div>
              </div>
              <div className={styles.page}>
                <section className={styles.closer}>
                  <div>
                    En las publicaciones posteriores, vamos a enfocarnos en una habilidad básica de la lengua. Así, en
                    una semana vamos a estar muy enfocados mejorando nuestra habilidad para escuchar y hablar, en otra
                    para leer y en otra para escribir.
                  </div>
                  <div className={styles.bigIcons}>
                    <img className={styles.img} src={require("../images/speaking.svg")} alt={"speaking"}/>
                    <img className={styles.img} src={require("../images/reading.svg")} alt={"reading"}/>
                    <img className={styles.img} src={require("../images/writing.svg")} alt={"writing"}/>
                    <img className={styles.img} src={require("../images/listening.svg")} alt={"listening"}/>
                  </div>
                </section>
              </div>
              <div className={styles.cta}><CallToAction onClick={toWeekOne}>Comenzar</CallToAction></div>
            </div> : <></>}
            {value === 1 ?
              <>
                <div className={css(styles.autoEstudio, styles.week)}>
                  <div className={styles.page}>
                    <IntroText header={"GUÍA DE AUTO-ESTUDIO"} subHeader={"WEEK 1 - CALENTAMIENTO"}
                               className={styles.full} youtubeId={"2l7WaaqqSV8"}>
                      <section className={styles.border}>
                        <p><span className={styles.start}>E</span>mpecemos con la herramienta de esta semana: <a
                          href="https://www.bbc.co.uk/learningenglish" target={"_blank"} rel="noopener noreferrer">BBC
                          Learning English</a></p>
                        <p>
                          Esta página web es muy completa y la usamos mucho en nuestra época como estudiantes de inglés;
                          Incluso en ocasiones, todavía la usamos, practicar un poco gramática o vocabulario.

                        </p>
                      </section>
                      <section className={styles.border}>
                        <p>En general es tanto el contenido, que uno se puede perder a la hora de elegir qué estudiar.
                          Pero la página tiene unos cursos que puedes seguir de manera consistente y usar los demás
                          recursos para practicar y seguir mejorando.
                        </p>

                        <p>Algo que nos gusta mucho de la página es que tiene una sección de noticias e incluso, una
                          sección dedicada al Covid-19 de manera, que un reto previo que te proponemos, es que en lugar
                          de
                          estar buscando y leyendo noticias sobre este tema en redes sociales o en portales de noticias
                          en
                          tu idioma, las leas en esta página; de esta manera, te estarás enterando de las noticias
                          actuales; y al mismo tiempo, estarás poniendo a trabajar el tiempo que inviertes informándote,
                          en la consecución de tu objetivo de estudiar inglés.
                        </p>
                      </section>
                    </IntroText>
                  </div>
                  <section className={styles.explainer}>
                    <h2>Usar la página</h2>
                    Te dejamos aquí una pequeña guía de como realizar los cursos en esta página:
                    <div>
                      <div>
                        <span className={styles.counter}>1. </span>Ingresa al <a
                        href="https://www.bbc.co.uk/learningenglish" target={"_blank"} rel="noopener noreferrer"> sitio
                        oficial de BBC Learning English </a> e
                        ingresa a “courses”

                      </div>
                      <Img className={css(styles.img)} sizes={data.homeBbc.childImageSharp.sizes}
                           alt={"bbc página"} imgStyle={{ objectFit: "contain" }}/>
                      <div>
                        <span className={styles.counter}>2. </span> Elige tu nivel de inglés para ingresar a un curso
                        apropiado para ti:
                      </div>

                      <div>
                        <span className={styles.counter}>3. </span>En el curso que eliges, vas a encontrar Unidades,
                        Secciones y actividades. Las unidades tienen
                        el propósito de enseñar un tema general
                      </div>
                      <Img className={css(styles.img)} sizes={data.bbcInter.childImageSharp.sizes}
                           alt={"image ejemplo offerta"} imgStyle={{ objectFit: "contain" }}/>
                    </div>
                  </section>
                  <section className={styles.perLevel}>
                    <h1>Estudia según tu nivel, haz click en tu nivel para seguir con las instrucciones!</h1>
                    <div className={styles.levelSelect}>
                      <div className={css({ [styles.active]: level === 0 })} onClick={() => setLevel(0)}>
                        <span>Basico</span>
                      </div>
                      <div className={css({ [styles.active]: level === 1 })} onClick={() => setLevel(1)}>
                        <span>Pre-intermedio</span>
                      </div>
                      <div className={css({ [styles.active]: level === 2 })} onClick={() => setLevel(2)}>
                        <span>Intermedio</span>
                      </div>
                    </div>
                    {level === 0 ? (
                        <div className={css(styles.text, styles.activities)}>
                          <h2>Actividades de la semana nivel básico.</h2>

                          <section>En este nivel la intención principal es que puedas articular
                            frases que te permitan mantener conversaciones básicas o inglés de supervivencia. En tu nivel
                            siempre se tienen dudas y algo de temor al aproximarse al idioma. El consejo mas importante
                            que
                            podemos darte es que vayas a tu ritmo y así te tome mucho tiempo el entender los detalles de
                            cada lección, no pases a la siguiente hasta que no sientas realmente que la entiendes 100%. Sí
                            tienes dudas, significa que debes revisarla de nuevo.
                          </section>
                          <h2>Lo que debes hacer esta semana y consejos para lograrlo.</h2>
                          <Img className={css(styles.img)} sizes={data.basico.childImageSharp.sizes}
                               alt={"session info"} imgStyle={{ objectFit: "contain" }}/>
                          <section>
                            Estudia y realiza todos los ejercicios de la unidad 1. Para ello, como probablemente se te
                            haga
                            difícil seguir los diálogos en inglés, entonces te aconsejamos:
                          </section>

                          <ul>
                            <li>
                              Lee las transcripciones de los diálogos, intentando entender sin usar traductor o
                              diccionario.
                            </li>
                            <li>
                              Después de ello usa diccionario o traductor para entender las palabras que no entiendas, (si
                              son todas o casi todas, no te estreses, es absoutamente normal).
                            </li>
                            <li>
                              Para los ejercicios en los que se propone un diálogo en grupo, si no tienes nadie con quién
                              hablar, habla en voz alta. Te puede parecer extraño hacerlo; pero en realidad, es una de las
                              cosas que más ayuda para poder aprender a hablar una nueva lengua de manera fluida.
                            </li>
                          </ul>

                          <section className={styles.objectives}>
                            <h2>Tus objetivos:</h2>
                            Es un gran logro poder aprender y entender algunas frases de supervivencia, que eventualmente
                            puedas usar en un viaje o alguna situación inesperada. Con el contenido que te recomendamos
                            deberías:
                            <div>
                              <div>
                                ✅ Saber responder a un saludo de forma amigable.
                              </div>
                              <div>
                                ✅ Saber iniciar una conversación
                              </div>
                              <div>
                                ✅ Saber preguntar por una dirección o una instrucción para llegar a algún lugar.
                              </div>
                            </div>
                          </section>
                        </div>
                      )
                      :
                      level === 1 ?
                        <div className={css(styles.text, styles.activities)}>
                          <h2>Actividades nivel pre-intermedio</h2>
                          <section>En este nivel vas a ver las estructuras gramaticales, básicas de la lengua. Estas
                            estructuras, son la base del inglés; por tanto es de vital importancia que las estudies
                            poniendo todos tus sentidos para aprenderlas e incorporarlas en la practica de tu inglés.
                          </section>
                          <h2>Lo que debes hacer esta semana y consejos para lograrlo.</h2>
                          <Img className={css(styles.img)} sizes={data.lowerIntermediate.childImageSharp.sizes}
                               alt={"session info"} imgStyle={{ objectFit: "contain" }}/>
                          <section>
                            Estudia y realiza los ejercicios de las unidades 1,2 y 3 en donde vas a aprender:
                          </section>
                          <ul>
                            <li>
                              Estructura de las preguntas
                            </li>
                            <li>
                              Presente simple y presente continuo
                            </li>
                            <li>
                              Como usar “like” para expresar preferencias y como usarlo para describir algo.
                            </li>
                          </ul>
                          <p>
                            Trata entender los videos a partir del audio y las transcripciones, tanto como sea posible.
                            Repítelos una y otra vez, hasta que logres entender las frases en cada video de forma
                            cómoda.
                          </p>
                          <p>
                            Procura buscar los significados de las palabras que no conozcas en un diccionario de inglés
                            tradicional; es decir sin traducciones. A partir de tu nivel es muy importante que no trates
                            de traducir al español cada palabra o frase que no conozcas; sino que intentes entenderlas a
                            partir de los significados y/o sinónimos que te ofrezca la misma lengua (no sabes como
                            mejora
                            el vocabulario adquirir este habito). Puedes además apoyarte en el apartado de vocabulario
                            que
                            tiene cada unidad.
                          </p>
                          <p>
                            La parte de gramática es la que tal vez te va a tomar más tiempo y en la que debes enfocar
                            tu
                            esfuerzo. No basta con que la entiendas; haz los ejercicios, trata de entender cada uno
                            hasta
                            dominarlo. Sí te equivocas en las respuestas, tomate el tiempo para entender porque te
                            equivocaste y cual es la respuesta correcta.
                          </p>
                          <section className={styles.objectives}>
                            <h2>Tu objetivo:</h2>
                            <strong>Sí te gustan los retos, debes realizar los test de las 3 unidades hasta lograr
                              responder todo de forma correcta. La razón? Cuando realmente quieres aprender un idioma,
                              no
                              basta con entender el 70% u el 80% de lo que estudias. Buscar la excelencia te llevará al
                              objetivo!</strong>
                          </section>
                        </div>
                        : <div className={css(styles.text, styles.activities)}>
                          <h2>Actividades de la semana nivel Intermedio.</h2>
                          <section>En este nivel es donde mas cuidado debes tener al revisar con ligereza, los temas que
                            te
                            son familiares o que crees que entiendes, porque una cosa es entender una regla gramatical y
                            otra, aplicarla a las 4 habilidades básicas de la lengua. En el contenido que te recomendamos
                            esta semana, vas a recordar o reforzar tus conocimientos en los siguientes temas, que son
                            fundamentales para empezar a interactuar en un inglés un poco más elaborado:
                          </section>
                          <h2>Lo que debes hacer esta semana y consejos para lograrlo.</h2>
                          <Img className={css(styles.img)} sizes={data.intermediate.childImageSharp.sizes}
                               alt={"session info"} imgStyle={{ objectFit: "contain" }}/>
                          <section>
                            Estudia y realiza todas las actividades propuestas en las unidades 1,2 y 3 en donde vas a
                            aprender:
                          </section>
                          <ul>
                            <li>
                              Presente simple, presente continuo y presente perfecto
                            </li>
                            <li>
                              Presente perfecto continuo
                            </li>
                            <li>
                              Cuantificadores
                            </li>
                          </ul>
                          <p>
                            Tomándote tu tiempo en cada lección para escuchar los audios, entender las expresiones y
                            repetir
                            las veces que sea necesario, a fin de que sientas no solo que entiendes los tiempos verbales y
                            las expresiones; sino que realmente te eres capaz de usarlos en el contexto de una
                            conversación.
                          </p>
                          <p>
                            Aprender una lengua se trata de entender, aplicar y repetir. Entre más veces repitas este
                            ciclo
                            a consciencia, mejores van a ser los resultados.
                          </p>
                          <p>
                            Es muy común que mientras estudias algunos de los temas, pienses que ya los conoces; te
                            invitamos a que vuelvas y te preguntes de nuevo sí el tema es solamente familiar para ti o si
                            lo
                            dominas de tal forma, que al usarlo de forma hablada lo haces de manera fluida y al escuchar
                            los
                            diálogos, lo haces sin ninguna dificultad.
                          </p>
                          <section className={styles.objectives}>
                            <h2>Tu objetivo:</h2>
                            <strong>Es a partir de tu nivel, que realmente empiezas a sentir confianza comunicándote en
                              inglés; y estudiando las 3 primeras unidades con toda tu concentración, vas a poder tener un
                              inglés con expresiones un poco mas fluidas, para desenvolverte en un contexto menos
                              preparado
                              como el aula de clases y mas real.</strong>
                          </section>
                        </div>}
                    <div className={styles.closer}>
                      <h2>Para cerrar unos tips</h2>
                      <section>
                        <span className={styles.counter}>1. </span> Tómate tu tiempo para realizar cada actividad,
                        sobretodo para entenderla y dominarla. Cuando
                        estudias inglés no solo es necesario que entiendas el tema; sino que seas capaz de usar ese
                        conocimiento de una manera espontánea.
                      </section>
                      <section>
                        <span className={styles.counter}>2. </span> La practica es el mejor maestro. Repite, repite y
                        repite, es la mejor manera de afianzar el
                        conocimiento.
                      </section>
                      <section>
                        <span className={styles.counter}>3. </span> No se aprende si estás a las carreras o distraído.
                        Es
                        mucho mejor si te propones a estudiar 20 o
                        30 minutos diarios con 100% de concentración, que si te sientas dos horas a estudiar, con
                        interrupciones de redes sociales abiertas, chats o conversaciones con amigos entre otros.
                      </section>
                      <section>
                        <span className={styles.counter}>4. </span> <strong>No olvides la próxima semana tendremos la
                        segunda parte de este curso </strong>, con una aproximación
                        diferente y con muchos otros recursos para que mejores tu inglés.
                      </section>
                    </div>
                  </section>
                </div>
                <Footer/>
              </>
              : value === 2 ?
                (<div className={css(styles.autoEstudio, styles.week)}>
                  <div className={styles.page}>
                    <IntroText header={"GUÍA DE AUTO-ESTUDIO"} subHeader={"WEEK 2 - READING AND WRITING"}
                               className={styles.full} youtubeId={"JqUPr1c_la8"}>
                      <section className={styles.border}>
                        <p><span className={styles.start}>L</span>eer y escribir en inglés son habilidades más
                          fundamentales a la hora de aprender inglés. Ello puede sonar obvio, pero existen casos de
                          personas que tienen la habilidad de comunicarse de forma oral en inglés, pero a la hora de
                          enviar leer instrucciones o escribir un email o un requerimiento, se quedan cortos.</p>
                        <p>Indudablemente tener un buen inglés escrito, es una habilidad importante cuando quieres
                          aplicar a un trabajo donde se te exige un buen nivel de inglés, porque en dichos trabajos, por
                          lo general te vas a ver enfrentado a escribir correos y leer documentos, comunicaciones y
                          correos en el idioma; naturalmente, como lo dije anteriormente, acompañado de las habilidades
                          de hablar y escuchar.
                        </p>
                        <p>
                          Esta semana nos hemos propuesto entonces estructurar un material con el que podrás mejorar tu
                          lectura y escritura en inglés. Para ello, es importante que tengas en cuenta dos factores:
                        </p>
                        <p>
                          El primero, es que no pases por alto las palabras que no conozcas; sino que trates de
                          entenderlas dentro del contexto y luego si aún no logras entender su significado, las busques
                          en un diccionario de inglés- inglés. Solo como ULTIMO recurso, puedes utilizar un diccionario.
                        </p>
                      </section>
                      <section className={styles.border}>
                        <p>Para iniciar entonces, tenemos <a
                          href="https://www.nytimes.com/2020/05/05/learning/when-the-pandemic-ends-will-school-change-forever.html"
                          target={"_blank"} rel="noopener noreferrer">un artículo muy interesante del NY Times</a>, que
                          puede ser leído desde nivel básico. Obviamente, en la medida que tu inglés sea mejor, podrás
                          leerlo con mayor fluidez; pero no te preocupes sí te toma mucho tiempo, porque en este
                          programa de auto-estudio, la idea es que lleves el estudio a tu ritmo.
                        </p>
                        <p>AAl final del artículo encontrarás unas preguntas, sobre las cuales puedes dejar tus
                          comentarios; una excelente forma de practicar tu “writing”.
                        </p>
                      </section>
                    </IntroText>
                  </div>
                  <section className={styles.explainer}>
                    <h2>El sitio web de esta semana</h2>
                    El sitio web recomendado para esta semana es LearnEnglish, British Council. Este es un sitio web
                    con excelentes recursos para mejorar todas tus habilidades en inglés y lo hemos elegido, pues
                    tiene actividades especificas para mejorar tu lectura y escritura. Tal como hicimos la semana
                    anterior, te dejamos a continuación las instrucciones generales para que posteriormente realices
                    las actividades según tu nivel.
                    <div>
                      <div>
                        <span className={styles.counter}>1. </span>Ingresa al <a
                        href="http://learnenglish.britishcouncil.org" target={"_blank"}
                        rel="noopener noreferrer"> sitio web del British Counsil </a> y en la parte superior párate
                        sobre “skills”, selecciona Reading y luego selecciona tu nivel.
                      </div>
                      <Img className={css(styles.img)} sizes={data.semanaDos.childImageSharp.sizes}
                           alt={"Website British council"} imgStyle={{ objectFit: "contain" }}/>
                      <div>
                        <span className={styles.counter}>2. </span> Realiza las actividades propuestas de acuerdo a tu
                        nivel con los tips que te dejamos más adelante para ello.
                      </div>

                      <div>
                        <span className={styles.counter}>3. </span>Una vez realices las actividades de “Reading”,
                        repite los pasos 1 y 2 pero ingresando a “Writing”.
                      </div>

                    </div>
                  </section>
                  <section className={styles.perLevel}>
                    <h1>Estudia según tu nivel, haz click en tu nivel para seguir con las instrucciones!</h1>
                    <div className={styles.levelSelect}>
                      <div className={css({ [styles.active]: level === 0 })} onClick={() => setLevel(0)}>
                        <span>Basico</span>
                      </div>
                      <div className={css({ [styles.active]: level === 1 })} onClick={() => setLevel(1)}>
                        <span>Pre-intermedio</span>
                      </div>
                      <div className={css({ [styles.active]: level === 2 })} onClick={() => setLevel(2)}>
                        <span>Intermedio</span>
                      </div>
                    </div>
                    {level === 0 ? (
                      <div className={css(styles.text, styles.activities)}>
                        <h2>Actividades de la semana nivel básico.</h2>

                        <section>Uno de los retos mas grandes que tienes en tu nivel, es perderle el miedo a
                          enfrentarte a un texto en inglés y entenderlo; así como escribir textos básicos cuando
                          interactúas en redes, necesitas enviar un email o llenar un formulario. Ello es natural,
                          porque apenas empiezas a formar tus bases en la lengua; pero en especial, porque el
                          conocimiento que tienes de las estructuras gramaticales y vocabulario, todavía son muy
                          básicos
                        </section>
                        <h2>Lo que debes hacer esta semana y consejos para lograrlo</h2>
                        <section>
                          <span className={styles.counter}>1. </span>ingresa a la sección “Reading” y realiza todas las
                          actividades propuestos para el nivel A1; estas actividades son interactivas y tal vez no te
                          tome mucho tiempo en realizarlas, pero recuerda que lo mas importante es que entiendas lo que
                          lees, que respondas las preguntas entendiendo porqué eliges una opción sobre otra y que
                          termines cada actividad con una lectura final en la que puedas tranquilamente decir “entiendo
                          bien este texto.
                        </section>
                        <section>
                          <span className={styles.counter}>2. </span> Al igual que en la actividad anterior, ingresa a
                          la sección “Writing” y realiza todas las actividades propuestas para tu nivel. Un tip muy
                          importante que te damos para realizar el modulo de escritura, es que si bien las actividades
                          propuestas en la página, te permiten mediante un click contestar, asignar o reordenar las
                          palabras, te proponemos que tomes papel y reescribas las frases y respuestas de las
                          actividades. Cuando escribes, tu cerebro refuerza lo que aprendes. Adelante y disfruta las
                          actividades de esta semana!
                        </section>
                        <section className={styles.objectives}>
                          <h2>Tu objetivo de la semana </h2>
                          Esta semana como sabes, vamos a estudiar enfocados tanto en lectura como en escritura, pues
                          son dos habilidades que están muy conectadas. Tu objetivo de lectura de esta semana es
                          entender textos básicos tales como: el menú de un restaurante, una oferta de trabajo o una
                          invitación a un evento; y tu objetivo de escritura es aprender como escribir mensajes cortos,
                          diligenciar formularios y escribir post y comentarios en redes sociales! Qué tal si nos dejas
                          un comentario en nuestros post de la semana sobre tu experiencia con la guía de auto-estudio?
                          Recuerda que en instagram nos encuentras como <a
                          href={"https://www.instagram.com/desdemaltaoficial/"} target={"_blank"}
                          rel="noopener noreferrer">@desdemaltaoficial</a> y en Facebook como <a
                          href={"https://www.facebook.com/DesdeMalta"} target={"_blank"}
                          rel="noopener noreferrer">@desdemalta</a>.
                        </section>
                      </div>
                    ) : level === 1 ?
                      <div className={css(styles.text, styles.activities)}>
                        <h2>Actividades nivel pre-intermedio</h2>

                        <section>Esta semana el reto en tu nivel es interesante pues aunque tu probablemente tienes unas
                          bases que te permiten entender de manera general un texto escrito con un lenguaje sencillo,
                          ahora es momento de que lo leas y entiendas con mas profundidad, para que realmente vayas
                          avanzando hacía leer textos mas largos, complejos o técnicos.
                        </section>
                        <h2>Lo que debes hacer esta semana y consejos para lograrlo</h2>
                        <section>
                          <span className={styles.counter}>1. </span>ingresa a la sección “Reading” y realiza todas las
                          actividades propuestos para el nivel A2; estas actividades son interactivas y tal vez no te
                          tome mucho tiempo en realizarlas, pero es importante que mientras lees los textos, seas
                          consciente de los tiempos gramaticales y las estructuras que se utilizan, además de poner
                          mucha atención a las nuevas expresiones que encuentres en ellos. Leer ayuda inmensamente a
                          enriquecer el vocabulario que es una carencia muy común cuando estamos aprendiendo inglés.
                        </section>
                        <section>
                          <span className={styles.counter}>2. </span> Ingresa a la sección “Writing” y realiza todas las
                          actividades propuestas para tu nivel. No olvides tu papel y lápiz, tomar apuntes y seguir los
                          tips recomendados para cada una de las actividades. No te quedes solo con la respuesta de los
                          test interactivos; que si bien te ayudan a medir como está tu habilidad, se quedan cortos para
                          realmente lograr una mejora significativa en tu escritura. Puede que te cueste escribir
                          textos, pues es una de las habilidades que mas nos tardamos en mejorar, por ello, aprovecha
                          esta motivación que tienes, para que mejores en ello y adelante!
                        </section>
                        <section className={styles.objectives}>
                          <h2>Tu objetivo de la semana </h2>
                          Leer y escribir son habilidades complementarias y esta semana tu objetivo es entrenar tus
                          habilidades de lectura, para ser capaz de encontrar información especifica en un texto. Esta
                          habilidad es especialmente importante a la hora de presentar un examen internacional, donde la
                          lectura eficiente, te ayuda a extraer detalles importantes del texto y a manejar muy bien el
                          tiempo. Tu segundo objetivo, esta relacionado con la escritura y va enfocado a empezar a
                          redactar textos como emails, cartas de motivación, perfiles entre otros, que son
                          indudablemente útiles en contextos laborales, académicos e incluso personales.
                        </section>
                      </div>
                      : <div className={css(styles.text, styles.activities)}>
                        <h2>Actividades nivel intermedio</h2>

                        <section>La invitación que te hacemos esta semana, es a que mejores tus habilidades de lectura y
                          escritura, enfrentándote a textos con un lenguaje mas apropiado para un contexto especifico;
                          en el cual se pueden combinar, expresiones y tiempos verbales, entendiendo el mensaje
                          principal de cada uno y sabiendo como abordarlos, según el tipo de texto de que se trate.
                          Algunos de los textos son artículos de prensa, guían, emails entre otros.
                        </section>
                        <h2>Lo que debes hacer esta semana y consejos para lograrlo</h2>
                        <section>
                          <span className={styles.counter}>1. </span>ingresa a la sección “Reading” y realiza todas las
                          actividades propuestos para el nivel B1; estas actividades son interactivas y tal vez no te
                          tome mucho tiempo en realizarlas, pero es importante que mientras lees los textos, seas
                          consciente del estilo en que se escribe cada texto dependiendo del contexto y el tipo de
                          vocabulario y expresiones que se emplean. Leer es un habito que te va a ayudar a llevar tu
                          inglés a otro nivel!
                        </section>
                        <section>
                          <span className={styles.counter}>2. </span> Ingresa a la sección “Writing” y realiza todas las
                          actividades propuestas para tu nivel. Es muy importante que no te confíes solamente de las
                          actividades propuestas y la metodología que emplean; sino que te tomes tu tiempo para
                          practicar tu escritura de manera real. Una buena forma de practicar es participando en los
                          foros propuestos en cada actividad.
                        </section>
                        <section className={styles.objectives}>
                          <h2>Tu objetivo de la semana </h2>
                          <div>Tienes dos objetivos esta semana, el primero tiene que ver con tus habilidades de lectura
                            y esta semana queremos que tengas como objetivo realizar todas las actividades propuestas
                            para tu nivel, siendo más consciente de las expresiones usadas en inglés, que no tienen una
                            traducción literal en español, pero que son frecuentemente usadas.
                          </div>
                          <div>
                            Respecto al objetivo de escritura, implica un esfuerzo adicional, pues se trata de que
                            puedas crear escritos simples, en inglés; para ello, es fundamental que cuides mucho los
                            tiempos verbales en que escribes y las preposiciones. Esta semana sin duda, va a ser un poco
                            más exigente que la anterior, respecto a los objetivos que tienes por cumplir; y sí la
                            estudias a conciencia, vas a tener un avance significativo en tu nivel.
                          </div>
                        </section>
                      </div>
                    }
                  </section>

                </div>) :
                value === 3 ?
                  (<div className={css(styles.autoEstudio, styles.week)}>
                    <div className={styles.page}>
                      <IntroText header={"GUÍA DE AUTO-ESTUDIO"} subHeader={"WEEK 3 - LISTENING"}
                                 className={styles.full} youtubeId={"WYvC2XGFxk0"}>
                        <section className={styles.border}>
                          <p><span className={styles.start}>C</span>omenzamos hoy con la tercera semana de esta guía de
                            auto-estudio, que esperamos estén aprovechando al máximo y sobretodo a su ritmo. Esta semana
                            la vamos a dedicar a mejorar nuestras habilidades para escuchar en inglés.
                          </p>
                          <p>Escuchar supone un gran desafío y es junto con la habilidad de hablar, las que siempre
                            tenemos más afán de desarrollar y por ello, las que nos dan la sensación de que mas nos
                            cuestan. Por ello, en las actividades de esta semana, vas a ver como las actividades de
                            “listening” llevan consigo siempre la ayuda de textos escritos, que te permitan seguir las
                            conversaciones y descifrar esas palabras o expresiones que con solo escuchar, no logras
                            entender.
                          </p>
                        </section>
                        <section className={styles.border}>
                          Escuchar es una habilidad fundamental cuando quieres conocer personas o entablar
                          conversaciones en inglés y por ello, les queremos dejar tal como en la semana pasada, un video
                          común a todos los niveles. Este no va a ser muy fácil de entender en principio, por lo cual
                          les sugerimos:
                          <div>
                            <div>
                              <span className={styles.counter}>1. </span>Ver el video sin subtítulos, intenta entender
                              lo máximo posible. (no importa si no entiendes mas que unas muy pocas palabras, es
                              completamente normal).
                            </div>
                            <div>
                              <span className={styles.counter}>2. </span> Activa los subtítulos en inglés y mira el
                              video nuevamente con dichos subtítulos y con mucha concentración. Esta vez debes entender
                              un poquito mas.
                            </div>

                            <div>
                              <span className={styles.counter}>3. </span>Sí sientes que entiendes el 70% del video,
                              repite los pasos uno y dos, acompañado de un diccionario inglés - inglés, para ir parando
                              el video y buscando los significados de las palabras desconocidas.
                            </div>
                            <div>
                              <span className={styles.counter}>4. </span> Sí entiendes menos del 70% del video, Activa
                              una única vez los subtítulos en español. Lee los subtítulos mientras escuchas; pero usa
                              los subtítulos como apoyo y el audio y la imagen como elementos principales para entender
                              el video; no lo contrario.
                            </div>
                            <div>
                              <span className={styles.counter}>5. </span> Repite todos los pasos anteriores hasta que te
                              sientas satisfecho con tu comprensión del video.
                            </div>
                          </div>
                          <section>Hay una razón por la cual elegimos este video como introducción a esta semana; y es
                            que saber
                            escuchar y estar abiertos a lo que el otro tiene para decirnos, es una habilidad que parece
                            perdida en nuestros tiempos y que hace falta recuperar.
                          </section>
                          <VideoContainer id={""} description="Video de Ted.com: 10 Ways to have a better conversation"
                                          title={"10 Ways to have a better conversation"}>
                            <iframe
                              src="https://embed.ted.com/talks/celeste_headlee_10_ways_to_have_a_better_conversation"
                              frameBorder="0" scrolling="no" allowFullScreen></iframe>
                          </VideoContainer>
                        </section>
                      </IntroText>
                    </div>
                    <section className={styles.explainer}>
                      <h2>El sitio web de esta semana</h2>
                      El sitio web que hemos elegido esta semana es elllo.org y la hemos elegido porque es una página en
                      la cual puedes encontrar multiples recursos para practicar tus habilidades en “listening”; pero
                      además, porque puedes escuchar diferentes acentos, reforzar tu vocabulario que ya hemos mencionado
                      antes en esta guía, es fundamental para tener fluidez en el inglés y poner a prueba tus
                      habilidades con el quiz que trae cada actividad.
                      <div>
                        <div>
                          <span className={styles.counter}>1. </span>Ingresa a <a
                          href="http://elllo.org" target={"_blank"}
                          rel="noopener noreferrer"> elllo.org </a> y da click sobre el recuadro azul en la parte
                          superior de “ Listening Levels”

                        </div>
                        <Img className={css(styles.img)} sizes={data.elllo.childImageSharp.sizes}
                             alt={"Website Elllo.org"} imgStyle={{ objectFit: "contain" }}/>
                        <div>
                          <span className={styles.counter}>2. </span> Elige las actividades propuestas según tu nivel A1
                          para básico, A2 para pre-intermedio y B1 para intermedio. Esta categoría la puedes ver en la
                          parte inferior de cada recuadro.

                        </div>

                        <div>
                          <span className={styles.counter}>3. </span>Realiza todas las actividades propuestas para tu
                          nivel que comprenden: Audio, gramática o vocabulario y quiz.
                        </div>

                      </div>
                    </section>
                    <section className={styles.perLevel}>
                      <h1>Estudia según tu nivel, haz click en tu nivel para seguir con las instrucciones!</h1>
                      <div className={styles.levelSelect}>
                        <div className={css({ [styles.active]: level === 0 })} onClick={() => setLevel(0)}>
                          <span>Basico</span>
                        </div>
                        <div className={css({ [styles.active]: level === 1 })} onClick={() => setLevel(1)}>
                          <span>Pre-intermedio</span>
                        </div>
                        <div className={css({ [styles.active]: level === 2 })} onClick={() => setLevel(2)}>
                          <span>Intermedio</span>
                        </div>
                      </div>
                      {level === 0 ? (
                        <div className={css(styles.text, styles.activities)}>
                          <h2>Actividades de la semana nivel básico.</h2>
                          <section>Esta semana supone un gran reto para tu nivel, y es tener la paciencia y persistencia
                            suficiente para escuchar y entender. Queremos reiterarte que esta no es una habilidad que se
                            aprende de la noche a la mañana y que requiere que practiques mucho, para que tu cerebro
                            empiece a entender de forma más rápida lo que escuchas. Tus actividades por tanto esta
                            semana están enfocadas en escuchar con atención los diálogos sencillos según tu nivel y
                            apoyarte el las transcripciones y gramática, para que cada vez puedas ir entendiendo mejor.
                          </section>
                          <h2>Lo que debes hacer esta semana y consejos para lograrlo</h2>
                          <section>
                            <span className={styles.counter}>1. </span>Sigue las instrucciones generales que te dejamos
                            anteriormente y realiza todas las actividades que se encuentran en los recuadros denominados
                            Leve 1 y Level 2.
                          </section>
                          <section>
                            <span className={styles.counter}>2. </span> Tómate tiempo para escuchar los audios y
                            seguirlos con las transcripciones; trata de usar traducciones como último recurso.
                          </section>
                          <section>
                            <span className={styles.counter}>3. </span>
                            Aprovecha al máximo la sección de gramática de cada ejercicio de listening, porque además de
                            reforzar lo que has visto en las semanas anteriores, te servirá para entender aún mejor cada
                            audio.
                          </section>
                          <section>
                            <span className={styles.counter}>4. </span>
                            Realiza el quiz y sobretodo revisa las respuestas, asegurándote de entender el porqué de
                            cada respuesta en especial esas en las que no acertaste.
                          </section>
                          <section className={styles.objectives}>
                            <h2>Tu objetivo de la semana </h2>
                            <div>
                              <div>
                                ✅ Lograr seguir las conversaciones, con apoyo en las transcripciones
                              </div>
                              <div>
                                ✅ Poner a prueba tus habilidades hasta alcanza una buena calificación en el quiz de cada
                                actividad
                              </div>
                            </div>
                          </section>
                        </div>
                      ) : level === 1 ?
                        <div className={css(styles.text, styles.activities)}>
                          <h2>Actividades nivel pre-intermedio</h2>
                          <section>La habilidad de escuchar toma tiempo y requiere de mucha concentración y práctica. En
                            tu nivel ya empiezas a entender diálogos sencillos y es hora de que te esfuerces un poco más
                            en mejorar tu nivel. Esta semana tendrás muchas actividades para realizar, en las cuales
                            encontrarás conversaciones con diferentes acentos, en los cuales vas a tener un refuerzo
                            tanto de estructuras gramaticales, como de vocabulario.
                          </section>
                          <h2>Lo que debes hacer esta semana y consejos para lograrlo</h2>
                          <section>
                            <span className={styles.counter}>1. </span>Sigue las instrucciones generales que te dejamos
                            anteriormente y realiza todas las actividades que se encuentran en los recuadros denominados
                            Level 3. Con ellas tendrás la posibilidad de seguir mejorando tanto tú gramática como
                            vocabulario.

                          </section>
                          <section>
                            <span className={styles.counter}>2. </span> Tómate tiempo para escuchar los audios y
                            seguirlos con las transcripciones; Busca los significados de las palabras o expresiones que
                            no entiendas, en diccionario inglés - inglés.
                          </section>
                          <section>
                            <span className={styles.counter}>3. </span> Aprovecha al máximo la secciones de gramática y
                            vocabulario de cada ejercicio de listening, porque además de reforzar lo que has visto en
                            las semanas anteriores, te servirá para enriquecer tu vocabulario y entender aún mejor cada
                            audio.

                          </section>
                          <section>
                            <span className={styles.counter}>4. </span> Realiza el quiz y sobretodo revisa las
                            respuestas, asegurándote de entender el porqué de cada respuesta en especial esas en las que
                            no acertaste.

                          </section>
                          <section>
                            <span className={styles.counter}>5. </span> Por último y sí eres de los que les gusta
                            esforzarse un poquito más, trata de realizar los ejercicios del “Level 4”. Recuerda, no
                            corras! No se trata de correr a terminar lo mas que puedas; se trata de entender, aprender y
                            disfrutar el proceso.
                          </section>
                          <section className={styles.objectives}>
                            <h2>Tu objetivo de la semana </h2>
                            <div>
                              <div>
                                ✅ Entender las conversaciones en su contexto general con y sin el soporte de las
                                transcripciones
                              </div>
                              <div>
                                ✅ Alcanzar una buena calificación en cada quiz propuesto por conversación. Sí no lo
                                logras, repite la actividad hasta lograrloLo que debes hacer esta semana y consejos para
                                lograrlo
                              </div>
                            </div>
                          </section>
                        </div>
                        : <div className={css(styles.text, styles.activities)}>
                          <h2>Actividades nivel intermedio</h2>

                          <section>Tal vez la habilidad de escuchar es la que te hace dudar si algún día vas a poder
                            alcanzar un nivel alto de inglés, pues junto con conversar, son las que mas nos ponen a
                            prueba en situaciones reales. Por ello, esta semana te invitamos a realizar las actividades
                            propuestas para mejorar tu “listening” siempre retando tu oido y tu cerebro a entender al
                            máximo posible. Vas a escuchar audios de conversaciones cotidianas de personas con
                            diferentes acentos; esto es esencial, para mejorar tu nivel.
                          </section>
                          <h2>Lo que debes hacer esta semana y consejos para lograrlo</h2>
                          <section>
                            <span className={styles.counter}>1. </span>Sigue las instrucciones generales que te dejamos
                            anteriormente y realiza todas las actividades que se encuentran en los recuadros denominados
                            Level 4. Con ellas tendrás la posibilidad de seguir mejorando tanto tú gramática como
                            vocabulario.

                          </section>
                          <section>
                            <span className={styles.counter}>2. </span> Tómate tiempo para escuchar los audios y
                            seguirlos con las transcripciones; Busca los significados de las palabras o expresiones que
                            no entiendas, en diccionario inglés - inglés.

                          </section>
                          <section>
                            <span className={styles.counter}>3. </span> Aprovecha al máximo la secciones de gramática y
                            vocabulario de cada ejercicio de listening, porque además de reforzar lo que has visto en
                            las semanas anteriores, te servirá para enriquecer tu vocabulario y entender aún mejor cada
                            audio.

                          </section>
                          <section>
                            <span className={styles.counter}>4. </span> Realiza el quiz y sobretodo revisa las
                            respuestas, asegurándote de entender el porqué de cada respuesta en especial esas en las que
                            no acertaste.


                          </section>
                          <section>
                            <span className={styles.counter}>5. </span> Por último y sí eres de los que les gusta
                            esforzarse un poquito más, trata de realizar ejercicios de los niveles siguientes. Recuerda
                            siempre poner mucha atención en cada audio y al mismo tiempo tratar de no tensionarte,
                            porque bloqueas con ello tu cerebro para escuchar, que es una habilidad que llega con el
                            tiempo y la practica.
                          </section>
                          <section className={styles.objectives}>
                            <h2>Tu objetivo de la semana </h2>
                            <div>
                              <div>
                                ✅ Entender las conversaciones en su contexto general con y sin el soporte de las
                                transcripciones. No pases de una actividad a otra, hasta que no sientas, que entiendes y
                                sigues de manera cómoda la conversación
                              </div>
                              <div>
                                ✅ Alcanzar una buena calificación en cada quiz propuesto por conversación. Sí no lo
                                logras, repite la actividad hasta lograrlo
                              </div>
                            </div>
                          </section>
                        </div>
                      }
                    </section>
                  </div>)
                  :
                  value === 4 ? <div className={css(styles.autoEstudio, styles.week)}>
                      <div className={styles.page}>
                        <IntroText header={"GUÍA DE AUTO-ESTUDIO"} subHeader={"WEEK 4 - SPEAKING"}
                                   className={styles.full}>
                          <section className={styles.border}>
                            <p><span className={styles.start}>S</span>peaking! La habilidad mas deseada cuando pensamos en
                              aprender o mejorar nuestro inglés. Quien no ha escuchado a alguien hablar fluidamente en
                              inglés y pensar en lo bueno que sería poder hablar así de bien? Pues bien, está semana les
                              traemos unas herramientas espectaculares, creadas en inteligencia artificial y pensadas para
                              corregir específicamente los errores al hablar que tenemos los hispanohablantes. Aquí te
                              contamos como funcionan y porqué te van a encantar!
                            </p>
                            <p>No sobra decir, que hablar de una forma fluida, es a veces el resultado, del trabajo
                              constante en todas las habilidades en su conjunto; por ello, sí no has realizado las
                              actividades propuestas en las semanas anteriores, para la mejora de tu lectura, escritura y
                              escucha, te sugerimos que lo hagas antes de iniciar con las actividades propuestas para esta
                              semana de nuestra guía de auto-estudio.
                            </p>
                          </section>
                          <section className={styles.border}>
                            <p>Para este modulo, teníamos dos retos. El primero era darles herramientas que les permitiera
                              practicar y mejorar sus habilidades para hablar sin tener que estar necesariamente con otra
                              persona; y el segundo, tiene que ver con la pregunta frecuente de quienes nos contactan,
                              acerca del acento que aprenderán si deciden estudiar inglés en Malta; y aunque lo que
                              siempre
                              les decimos, es que mas allá del acento, lo que mas importa para hablar bien en inglés es
                              tener una correcta pronunciación, con las actividades que les vamos dejar esta semana, van a
                              poder comprobarlo por si mismos. Finalmente, el acento es algo propio de cada país que
                              mientras no interfiera con la comunicación, no tendría porque inquietarnos.</p>
                          </section>
                        </IntroText>
                      </div>
                      <section className={css(styles.explainer)}>
                        <h2>Herramientas de esta semana</h2>
                        <p>Pues bien, esta semana hemos elegido 3 recursos para mejorar las habilidades para hablar en
                          ingles. El primero, es un documento escrito específicamente para hispanohablantes, en el cual
                          se
                          hace una lista de los errores de pronunciación más comunes que cometemos al hablar en inglés y
                          la forma de corregirlos.</p>
                        <p>El segundo, es una app llamada “Tflat English Pronunciation” y elegimos esta app, pues uno de
                          los secretos más importantes a la hora de comunicarnos bien cuando hablamos en inglés, es
                          tener una correcta pronunciación de las palabras. En esta app pondrás en practica lo que verás
                          en el documento.</p>
                        <p>El tercer recurso, es muy innovador e interesante, se trata de una app construida con
                          inteligencia artificial, la cual califica no solo tu pronunciación; sino la entonación
                          correcta de las palabras. Es una app increíble que si la usas con persistencia y dedicación,
                          definitivamente va a llevar tus habilidades para hablar en inglés a otro nivel</p>
                        <div>
                          <div className={css(styles.text, styles.activities)}>
                            <h2>Actividades comunes a todos los niveles.</h2>
                            <span className={styles.counter}>1. </span>Ingresa al <a
                            href="http://www.tedpower.co.uk/l1spanish.html " target={"_blank"}
                            rel="noopener noreferrer"> sitio web </a> revisa y practica con cuidado cada uno de los
                            sonidos que aparecen en la lista. En la parte izquierda encontrarás las instrucciones acerca
                            de la posición en que deben ir tu, boca, lengua etc para realizar el sonido; y en la columna
                            derecha, ejercicios extras para familiarizarte con esa pronunciación especifica.


                          </div>

                          <div>
                            <span className={styles.counter}>2. </span> Una vez hayas realizado todos los ejercicios
                            propuestos en el artículo anterior, descarga la app “Tflat English Pronunciation” y realiza
                            los ejercicios de pronunciación propuestos para cada sonido. No olvides activar el micrófono
                            para que la app califique tu pronunciación. Es retador, pero no avances al sonido siguiente,
                            hasta que obtengas al menos 4 estrellas en la calificación.
                          </div>
                          <Img className={css(styles.img)} sizes={data.tflat.childImageSharp.sizes}
                               alt={"App Tflat"} imgStyle={{ objectFit: "contain" }}/>
                          <div>
                            <span className={styles.counter}>3. </span>Solo cuando hayas superado las dos actividades
                            anteriores, descarga la app “elsa” realiza el test para determinar tu nivel en cada una de las
                            21 habilidades que analiza la app, programa tu horario de entrenamiento y trata de tener
                            disciplina en cumplirlo toda esta semana. Por supuesto, si continuas con tu disciplina de
                            estudio por más tiempo, mejores resultados tendrás!
                          </div>
                          <Img className={css(styles.img)} sizes={data.elsa.childImageSharp.sizes}
                               alt={"App Elsa"} imgStyle={{ objectFit: "contain" }}/>

                        </div>
                      </section>
                      <div className={css(styles.text, styles.activities)}>
                        <section className={styles.objectives}>
                          <h2>Tus objetivos:</h2>
                          Esta semana, vas a trabajar en mejorar tu pronunciación y entonación en inglés, para lograr:
                          <div>
                            <div>
                              ✅ Reconocer los diferentes sonidos que existen en inglés y no son comunes en español

                            </div>
                            <div>
                              ✅ Familiarizarte con la pronunciación de los nuevos sonidos en inglés y la entonación de las
                              palabras

                            </div>
                            <div>
                              ✅ Mejorar tus habilidades de hablar, en general, de manera que interfieran lo menos posible
                              en la comunicación de tus mensajes durante una conversación. Para todos los niveles,
                              independiente de cual sea el tuyo, estas actividades te ayudarán muchísimo a construir tus
                              habilidades para hablar en inglés de manera fluida
                            </div>
                          </div>
                        </section>
                        <h2>Lo que debes hacer esta semana y consejos para lograrlo</h2>
                        <section>
                          Esta semana tu reto es hablar y para ello debes realizar cada una de las actividades propuestas,
                          poniendo mucha atención y sobretodo persistencia, pues vas a estar aprendiendo formas de
                          pronunciar que son completamente extrañas a nuestra lengua y para lo cual no solo tienes que
                          poner a trabajar tu cerebro; sino que los movimientos faciales y bucales nuevos para pronunciar
                          correctamente, seguramente resultarán retadores en muchos momentos mientras estudias este
                          modulo.
                        </section>
                        <section>
                          Por ello, en esta semana en especial, no corras; en lugar de ello, practica, repite, trata de
                          entender en que está fallando tu pronunciación en cada actividad e intenta mejorarlo, hasta
                          llegar a un nivel con el que te sientas satisfecho.
                        </section>
                        <section>
                          Habla solo, realiza ejercicios para hablar con soltura y recuerda que tener un buen nivel de
                          speaking, requiere de mucha perseverancia, así que adelante!
                        </section>

                      </div>
                      <div className={css(styles.closer, styles.weekFour)}>
                        <h2>Por último</h2>
                        <section>Queremos felicitarte por haber llegado hasta aquí y haber superado todas las
                          actividades propuestas en esta guía de auto-estudio. Esperamos que la hayas aprovechado y
                          recuerda que sí te animas a tener una experiencia de inmersión que te permita seguir mejorando
                          tu inglés, puedes escribirnos, para juntos planear tu viaje.
                        </section>
                        <div><a href="https://mi.desdemalta.com/bienvenido/" target="_blank"
                                rel="noopener noreferrer">
                          <CallToAction
                            className={css(styles.callToAction, styles.second)}>Comienza
                            aquí</CallToAction></a></div>
                      </div>
                    </div>
                    : <></>
            }
          </Layout>
        )
      }}
    />)
}

export default AutoEstudio